import { render, staticRenderFns } from "./SHStaffSelectDialog.vue?vue&type=template&id=fd361a96&"
import script from "./SHStaffSelectDialog.vue?vue&type=script&lang=ts&"
export * from "./SHStaffSelectDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports