

















































import Vue from "vue";
import _ from "lodash";
import notificationMixin from "../../mixin/notification";
import store from "../../store/shop";
import ShopAPI from "../../common/api/api_shop";
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";

const translate = TranslateServiceBuilder.Instance()._;
const api = new ShopAPI();

export default Vue.extend({
  components: {
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
  },
  mixins: [notificationMixin],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      staffs: [],
      needSelect: false,
      isLoading: false,
      errors: "",
    };
  },
  mounted() {
    api.set_token(store.state.token);
    this.fetchStaffList();
  },
  computed: {
    styleDisplay() {
      if (this.needSelect) {
        return {
          display: "",
          opacity: "0",
        };
      }

      return {
        display: "none",
      };
    },
    styleZindex() {
      if (this.needSelect) {
        return {
          "z-index": "10000",
        };
      }

      return {
        "z-index": "",
      };
    },
  },
  methods: {
    handleClickChange: function (staff) {
      if (this.isLoading) {
        return;
      }
      store.commit("setStaffId", staff.id);
      store.commit("setStaffName", staff.nickname);
      this.needSelect = false;
      this.$emit("close");
    },
    handleClickClose() {
      if (this.needSelect) {
        return;
      }

      this.$emit("close");
    },
    fetchStaffList() {
      this.isLoading = true;
      let message = "";
      Promise.all([
        api
          .staffs_index()
          .then((response) => {
            if (response.data.status != "success") {
              message =
                response.data.message ||
                translate("hall-0048", "データの取得に失敗しました。");
              this.showErrorNotification(message);
              this.errors = message;
              return;
            }
            this.staffs = response.data.data.staffs;
            this.needSelect = response.data.data.needSelect;
          }),
      ])
      .then(() => {
        this.isLoading = false;
        this.$emit("open");
      })
      .catch((err) => {
        this.isLoading = false;
        message =
          err.response.data.message ||
          translate("hall-0048", "データの取得に失敗しました。");
        this.showErrorNotification(message);
        this.errors = message;
      });
    },
    setChecked (value) {
      if (value) {
        return "checked";
      }
    },
  },
});
